var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main",
    { staticClass: "movie-detail" },
    [
      _vm.movie == undefined
        ? [
            _c("h1", { staticClass: "heading" }, [_vm._v("Movie not found")]),
            _c(
              "p",
              { staticClass: "paragraph movie-detail__not-found" },
              [
                _vm._v(" The movie ID is incorret. Please go the "),
                _c(
                  "router-link",
                  { attrs: { to: { name: "MoviesOverview" } } },
                  [_vm._v("movies page")]
                ),
                _vm._v(". Or go back to the "),
                _c("router-link", { attrs: { to: { name: "IndexPage" } } }, [
                  _vm._v("homepage")
                ]),
                _vm._v(". ")
              ],
              1
            )
          ]
        : [
            _c("p", { staticClass: "movie-detail__back-button" }, [
              _c(
                "a",
                {
                  attrs: { href: "javascript:;" },
                  on: {
                    click: function($event) {
                      return _vm.$router.go(-1)
                    }
                  }
                },
                [_vm._v("Back")]
              )
            ]),
            _c("p", { staticClass: "paragraph movie-detail__poster-block" }, [
              _c("img", {
                staticClass: "movie-detail__poster",
                attrs: {
                  src: "/img/movies/" + _vm.movie.poster,
                  alt: _vm.movie.title
                }
              })
            ]),
            _c("div", { staticClass: "movie-details__body" }, [
              _c("h1", { staticClass: "movie-detail__title" }, [
                _vm._v(_vm._s(_vm.movie.title))
              ]),
              _c("p", { staticClass: "movie-detail__genre" }, [
                _vm._v(_vm._s(_vm.$options.filters.inlineList(_vm.movie.genre)))
              ]),
              _c("p", { staticClass: "movie-detail__year" }, [
                _vm._v(_vm._s(_vm.movie.year))
              ]),
              _c("p", { staticClass: "movie-detail__description" }, [
                _vm._v(_vm._s(_vm.movie.description))
              ]),
              _c("h4", { staticClass: "movie-detail__starring-title" }, [
                _vm._v("Starring")
              ]),
              _c("p", { staticClass: "movie-detail__actor-list" }, [
                _vm._v(
                  " " +
                    _vm._s(_vm.$options.filters.inlineList(_vm.movie.stars)) +
                    " "
                )
              ]),
              _c("h4", { staticClass: "movie-detail__rating" }, [
                _vm._v("Score: " + _vm._s(_vm.movie.rating))
              ])
            ]),
            _c("p", { staticClass: "movie-detail__external" }, [
              _c(
                "a",
                {
                  staticClass: "movie-detail__externa-link",
                  attrs: { href: _vm.movie.imdb, target: "_blank" }
                },
                [_vm._v("View on IMDb")]
              )
            ])
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }