var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("main", { staticClass: "movies" }, [
    _c("h1", { staticClass: "heading" }, [_vm._v("Movies")]),
    _vm._m(0),
    _c("p", { staticClass: "paragraph movies__sorting" }, [
      _vm._v(" Order by: "),
      _c(
        "a",
        {
          staticClass: "movies__sorting-link",
          class: [
            _vm.orderByDesc ? "sorted-desc" : "sorted-asc",
            { "is-active": _vm.order == "title" }
          ],
          attrs: { href: "javascript:;" },
          on: {
            click: function($event) {
              return _vm.setOrderBy("title")
            }
          }
        },
        [_vm._v(" Title ")]
      ),
      _vm._v(", "),
      _c(
        "a",
        {
          staticClass: "movies__sorting-link",
          class: [
            _vm.orderByDesc ? "sorted-desc" : "sorted-asc",
            { "is-active": _vm.order == "rating" }
          ],
          attrs: { href: "javascript:;" },
          on: {
            click: function($event) {
              return _vm.setOrderBy("rating")
            }
          }
        },
        [_vm._v(" rating ")]
      ),
      _vm._v(", "),
      _c(
        "a",
        {
          staticClass: "movies__sorting-link",
          class: [
            _vm.orderByDesc ? "sorted-desc" : "sorted-asc",
            { "is-active": _vm.order == "year" }
          ],
          attrs: { href: "javascript:;" },
          on: {
            click: function($event) {
              return _vm.setOrderBy("year")
            }
          }
        },
        [_vm._v(" year ")]
      )
    ]),
    _c(
      "section",
      { staticClass: "movies__list" },
      _vm._l(_vm.filteredMovies, function(movie) {
        return _c("MovieItem", { key: movie.id, attrs: { item: movie } })
      }),
      1
    )
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("p", { staticClass: "paragraph" }, [
      _vm._v("Movie overview- and detail page using "),
      _c("strong", [_vm._v("vue-router")]),
      _vm._v(", a dynamic URL using movie ID and an URL title slug.")
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }