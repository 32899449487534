var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "movie-item" }, [
    _c(
      "p",
      { staticClass: "movie-item__poster" },
      [
        _c(
          "router-link",
          {
            attrs: {
              tag: "a",
              to: {
                name: "MovieDetailPage",
                params: {
                  id: _vm.item.id,
                  slug: _vm.$options.filters.slugify(_vm.item.title)
                }
              },
              title: _vm.item.title,
              "active-class": "is-active",
              "exact-active-class": "is-exact"
            }
          },
          [
            _c("img", {
              staticClass: "movie-item__cinema-poster",
              attrs: {
                src: "/img/movies/" + _vm.item.poster,
                alt: _vm.item.title
              }
            })
          ]
        )
      ],
      1
    ),
    _c(
      "h3",
      { staticClass: "movie-item__title" },
      [
        _c(
          "router-link",
          {
            attrs: {
              tag: "a",
              to: {
                name: "MovieDetailPage",
                params: {
                  id: _vm.item.id,
                  slug: _vm.$options.filters.slugify(_vm.item.title)
                }
              },
              "active-class": "is-active",
              "exact-active-class": "is-exact"
            }
          },
          [_vm._v(" " + _vm._s(_vm.item.title) + " ")]
        )
      ],
      1
    ),
    _c("p", { staticClass: "movie-item__genre" }, [
      _vm._v(_vm._s(_vm.$options.filters.inlineList(_vm.item.genre)))
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }